@import '../../style/App.scss';
.blog__box {
    background-color: $white-color;
    width: 100%;
    max-width: 500px;
    min-height: 460px;
    position: relative;
    margin: 30px auto;
    .blog__image {
        width: 100%;
        height: auto;
        position: relative;
        img {
            width: 100%;
            height: auto;
            border-radius: 20px;
        }
        .blog__hover {
            cursor: pointer;
            transition: 0.3s ease;
            background-color: rgba($main-color, 0.8);
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 20px;
        }
    }
    .blog__image:hover {
        .blog__hover {
            opacity: 1;
        }
    }
    .blog__info {
        padding: 35px;
        h4 {
            text-transform: uppercase;
        }
    }
    .blog__button {
        background-color: $blue-color;
        color: $white-color;
        font-weight: 800;
        border-radius: 10px;
        padding: 15px 30px;
        border: 2px solid $blue-color;
    }
    .blog__button:hover {
        background-color: $white-color;
        color: $blue-color;
        border: 2px solid $main-color;
    }
    .blog_button_close {
        border-radius: 5px;
        background-color: #a0212d;
        border: 2px solid #a0212d;
        padding: 10px 20px;
        font-size: 14px;
    }
    .blog_button_submit {
        background-color: #0d0b4e;
        border-radius: 5px;
        border: 2px solid #0d0b4e;
        padding: 10px 20px;
        font-size: 14px;
    }
}