@import '../../style/App.scss';
#about {
    padding: 60px 0;
}

.para {
    margin-left: 50px;
    font-size: 1.5rem;
}

@media screen and (max-width: 990px) {
    #about {
        text-align: center;
    }
    .para {
        margin-left: 0;
    }
}